import React from "react";
import Layout from "../components/Layout";
import SolutionsHelmet from "../components/SolutionsHelmet/SolutionsHelmet";
import LearnAboutUs from "../components/Solutions/LearnAboutUs/LearnAboutUs";
import HeadlessCMSBanner from "../components/Platforms/HeadlessCMS/HeadlessCMS";
import JAMStackStaticSite from "../components/Platforms/JAMStack-static-site/JAMStackStaticSite";
import HeadlessCmsBenefits from "../components/Platforms/Headless-Cms-Benefits/HeadlessCmsBenefits";
import CmsServices from "../components/Platforms/CmsServices/CmsServices";
import { Helmet } from "react-helmet";

function HeadlessCMS() {
  const scrollTop = () => {
    window.scroll(0, 1);
  };
  return (
    <Layout><Helmet>
    <meta charSet="utf-8" />
       <title>
   Hashout Technologies - Headless CMS
       </title>
       <meta
         name="description"
         content="Headless CMS - Build a Content repository decoupled from frontend technologies lets brands easily deliver content across channels and devices."
       ></meta></Helmet>
      <SolutionsHelmet />
      <HeadlessCMSBanner />
      <JAMStackStaticSite />
      <HeadlessCmsBenefits />
      <CmsServices />
      <LearnAboutUs page="headless-cms" />
      <a className="go-to-top" href="javascript:void(0);" onClick={scrollTop}>
        <img
          className="go-to-top__img"
          src="../../solutions/go-to-top.png"
          alt="Go to top"
        />
      </a>
    </Layout>
  );
}
export default HeadlessCMS;
