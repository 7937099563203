import React, { useEffect, useState } from "react";
import ContactPopUp from "../../ContactPopUp";

function HeadlessCMSBanner() {
  const [background, setBackground] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <section className="section section--banner">
      <div className="container">
        <div className="container__row">
          <div className="container__col-md-6 --section-large-tb-padding">
            <h1 className="container__heading container__heading--platform container__heading--no-logo">
              Headless CMS{" "}
            </h1>
            <h2 className="container__sub-heading container__sub-heading--platform">
              Headless CMS - Build a Content repository decoupled from frontend
              technologies lets brands easily deliver content across channels
              and devices.{" "}
            </h2>
            <h6 className="container__content">
              Hashout works on a variety of Headless CMS products from open
              source to paid enterprise grade platforms typically hand-picked
              based on the suitability and the customer needs and usage.{" "}
            </h6>
            {/* <a className="container__link container__link--pad-bottom" href="">
              CLICK HERE to contrast and debate the need of Headless CMS vs
              Traditional CMS{" "}
            </a>
            <a className="container__link" href="">
              CLICK HERE to understand the top 5 reasons you will need Headless
              CMS for your organization{" "}
            </a> */}
            <button
              className="button container__button container__button--platform"
              onClick={openModal}
            >
              CONTACT US
            </button>
          </div>
          <div className="container__col-md-6 --flex-center">
            <img
              className="container__img --bg-absolute-right"
              src="../../solutions/platforms/headless-cms.png"
              alt="Headless CMS"
            />
          </div>
        </div>
      </div>
      <ContactPopUp
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        title="Let's Connect"
        subTitle="Fill out this short form, and we'll get back to you within one
        working day."
        section="Headless-Cms-Banner"
        page="Headless-Cms"
       
      />
    </section>
  );
}

export default HeadlessCMSBanner;
