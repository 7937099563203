import React from "react";

function HeadlessCmsBenefits() {
  return (
    <section className="section section--tools section--adobe-benefits section--bg-light-blue --section-medium-tb-padding">
      <div className="container">
        <div className="container__row">
          <div className="container__col-xs-12">
            <h2 className="container__heading">Headless CMS Benefits</h2>
          </div>
        </div>
        <div className="container__row --flex-center">
          <div className="container__col-md-4 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/platforms/benefits/omnichannel-delivery.png"
                alt="Omnichannel Delivery"
              />
              <a>
                <h5 className="card__title">Omnichannel Delivery</h5>
              </a>
              <p className="card__description">
                everything is pre-rendered HTML with dynamic functionality
                powered by APIs
              </p>
            </div>
          </div>
          <div className="container__col-md-4 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/platforms/benefits/content-focussed.png"
                alt="Cleaner separation of content from code"
              />
              <a>
                <h5 className="card__title">
                  Content Focussed (Channel Agnostic){" "}
                </h5>
              </a>
              <p className="card__description">
                Makes it easy to evolve technology independently
              </p>
            </div>
          </div>
          <div className="container__col-md-4 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/platforms/benefits/modern-api-first.png"
                alt="Modern and API first"
              />
              <a>
                <h5 className="card__title">Modern and API first</h5>
              </a>
              <p className="card__description">
                Ability to use React, Vue, and GO based static site which makes
                it both fun and efficient to build along with Git-based
                workflows and CDN for hosting which makes it highly secure too.
              </p>
            </div>
          </div>
          <div className="container__col-md-4 container__col-xs-12">
            <div className="card">
              <img
                className="card__image"
                src="../../solutions/platforms/benefits/flexibility.png"
                alt="Flexibility"
              />
              <a>
                <h5 className="card__title">Flexibility</h5>
              </a>
              <p className="card__description">
                several great tools and frameworks available to bootstrap and
                build. Easy to use CI/CD pipelines to provide branch-level
                continuous deployments increasing the speed and efficiency of
                development.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeadlessCmsBenefits;
